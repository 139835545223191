import React, { useState } from "react"
import {
  DriveFileRenameOutlineOutlined,
  EditOutlined,
  ReplyAllOutlined,
  ReplyAllRounded,
  ReplyAllSharp,
  ReplayCircleFilled,
  ReplayCircleFilledOutlined,
} from "@mui/icons-material"
import ReplyIcon from "@mui/icons-material/Reply"
import { ReviewsForm } from "./ReviewsForm"

export function ReviewsTableRows({ review, onEdit, setReviews, isModal }) {
  const [isFormVisible, setFormVisible] = useState(false)
  const [formType, setFormType] = useState("")
  const [selectedReviewId, setSelectedReviewId] = useState(null)

  console.log(review)

  const handleReplyClick = () => {
    setFormType("reply")
    setSelectedReviewId(review.id)
    setFormVisible(true)
  }

  const handleEditClick = () => {
    setFormType("edit")
    setSelectedReviewId(review.id)
    setFormVisible(true)
  }
  const handleClose = () => {
    setFormVisible(false)
  }

  return (
    <div className='bg-white p-4 rounded-lg shadow-sm mb-4'>
      {/* Верхняя часть: Имя, дата, рейтинг */}
      <div className='flex justify-between items-center'>
        {/* Имя автора и дата */}
        <div>
          <div className='flex gap-4'>
            <h4 className='font-bold text-lg'>{review.user.name}</h4>
            {review.answer === null && (
            <span className='bg-[#DBEAFE] rounded-2xl px-3 text-[14px] my-auto text-[#1E40AF]'>
              Новый
            </span>

            )}
          </div>
          <div className='flex items-center flex-row gap-4'>
            <p className='text-gray-500 text-sm'>{review.created_at}</p>
            <div className='text-yellow-500 font-bold text-lg mb-1'>
              {[...Array(5)].map((_, index) => (
                <span key={index}>{index < review.rating ? "★" : "☆"}</span>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Текст отзыва */}
      <div className='flex flex-col justify-start gap-6'>
        <p className='mt-2 text-darkGray text-sm px-1 break-words max-w-full'>
          {review.text}
        </p>
        {!review.answer && !review.answer && !isModal && (
          <div className='flex justify-start'>
            <button
              className='flex items-center text-blue-500 hover:underline h-2 text-sm'
              onClick={handleReplyClick}
            >
              <ReplyIcon style={{ fontSize: 16, marginBottom: 2 }} />
              ОТВЕТИТЬ
            </button>
          </div>
        )}
        {review.answer && (
          <div className='mt-4 p-4 bg-[#F8F8F8] rounded-xl'>
            <div className='flex flex-row justify-between'>
              <p className='font-bold text-sm text-gray-600'>Администратор:</p>
              {review.answer && !isModal && (
                <div className='flex justify-end'>
                  <button
                    className='flex items-center text-blue-500 hover:underline h-2 text-sm'
                    onClick={handleEditClick}
                  >
                    <DriveFileRenameOutlineOutlined style={{ fontSize: 16 }} />
                    <p className='hidden sm:block'>РЕДАКТИРОВАТЬ</p>
                  </button>
                </div>
              )}
            </div>
            <p className='text-darkGray w-auto text-sm mt-2 px-1'>
              {review.answer}
            </p>
          </div>
        )}
      </div>

      {isFormVisible && (
        <ReviewsForm
          type={formType}
          review={review}
          setReviews={setReviews}
          reviewId={selectedReviewId}
          active={isFormVisible}
          setActive={setFormVisible}
          onClose={handleClose}
        />
      )}
    </div>
  )
}
