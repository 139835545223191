import { IconButton, InputAdornment } from "@mui/material"
import React, { useEffect, useState } from "react"
import { MyController } from "./MyController"
import { InputBase } from "shared/ui/input/InputBase"
import { ContentCopy } from "@mui/icons-material"
import toast from "react-hot-toast"

const DeepLinkRedirect = ({ deepLink, control }) => {
  const [isRedirecting, setIsRedirecting] = useState(false)

  useEffect(() => {
    const fetchDeepLinkData = async () => {
      try {
        const response = await fetch(
          `https://test.api.navbat.tj/api/client/v1/deep-link?slug=${deepLink}`,
          {
            headers: {
              "Navbat-key": "kEX9oFXFkv0GGiOdPO55xgcZ4pAErUzNOoej3kwr",
              Accept: "application/json",
            },
          }
        )

        if (!response.ok) {
          throw new Error(`Ошибка: ${response.status}`)
        }

        const result = await response.json()
        const redirectUrl = `app.navbat.tj://salon/${result.salonId}`
        window.location.href = redirectUrl
      } catch (error) {
        console.error("Ошибка при получении данных deep link:", error)
      } finally {
        setIsRedirecting(false)
      }
    }

    if (isRedirecting) {
      fetchDeepLinkData()
    }
  }, [isRedirecting, deepLink])

  const handleClick = (e) => {
    e.preventDefault()
    setIsRedirecting(true)
  }
  const handleCopy = () => {
    navigator.clipboard.writeText(`app.navbat.tj/link/${deepLink}`)
    toast.success("Ссылка скопирована!")
  }

  return (
    <a href={`https://app.navbat.tj/link/${deepLink}`} onClick={handleClick}>
      <div className=' inline-block   w-full' onClick={handleCopy}>
        <MyController
          name='deepLink'
          control={control}
          render={({ field }) => (
            <InputBase
              {...field}
              value={` app.navbat.tj/link/${deepLink}`}
              readOnly
              // onClick={handleCopy}
              inputClassName='inputOutlined relative cursor-pointer bg-[#EDEDED]'
              labelClassName=' sm:mr-5 '
              // labelText='Ссылка для онлайн записи'
              style={{ cursor: "pointer" }}
            />
          )}
        />
        <IconButton
          onClick={handleCopy}
          className='absolute bottom-11 right-[-88%] sm:right-[-80%] 2xl:right-[-83%] pointer-events-none'
        >
          <ContentCopy
            style={{ fontSize: "1.7rem", transform: "scaleX(-1)" }}
          />
        </IconButton>
      </div>
    </a>
  )
}

export default DeepLinkRedirect;
