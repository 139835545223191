import React from "react"
import ReactInputMask from "react-input-mask"
import { forwardRef, useEffect, useState } from "react"

export const PhoneInput = forwardRef(
  (
    {
      value,
      onChange,
      disabled,
      error,
      autoFocus,
      className,
      name,
      onKeyDown,
      id,
    },
    ref
  ) => {
    const [maskedValue, setMaskedValue] = useState(value)

    // Обновляем значение с учетом маски, когда приходит новое значение извне
    useEffect(() => {
      if (value && value.startsWith("+992")) {
        setMaskedValue(value)
      } else {
        setMaskedValue(`+992${value}`)
      }
    }, [value])

    // Обработчик изменения значения
    const handleChange = (e) => {
      setMaskedValue(e.target.value)
      if (onChange) {
        onChange(e)
      }
    }
    //  const handleChange = (e) => {
    //   const rawValue = e.target.value.replace(/\D/g, ""); // Remove all non-numeric characters
    //   const withoutPrefix = rawValue.startsWith("992") ? rawValue.substring(3) : rawValue;
    //   setMaskedValue(`+992${withoutPrefix}`);
    //   if (onChange) {
    //     onChange({
    //       ...e,
    //       target: {
    //         ...e.target,
    //         value: withoutPrefix, // Send the value without the prefix
    //       },
    //     });
    //   }
    // };

    return (
      <>
        <ReactInputMask
          mask='+\9\92-999-999-999'
          maskPlaceholder={null}
          placeholder='+992 Введите ваш номер телефона'
          name={name || "phone"}
          value={maskedValue}
          onChange={handleChange}
          onKeyDown={onKeyDown}
          disabled={disabled}
        >
          <input
            ref={ref}
            id={id}
            type='text'
            className={`inputOutlined w-full ${
              error && "border-red-500 focus:border-red-500 "
            } ${className}`}
          />
        </ReactInputMask>
        {error && <p className='text-red-500 text-sm mt-1'>{error}</p>}
      </>
    )
  }
)
