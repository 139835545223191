import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { currentUrl } from "../../url";

const accessToken = localStorage.getItem("accessToken")


export const reviewsApi = createApi({
  reducerPath: "reviewsApi",
  baseQuery: fetchBaseQuery({ 
    baseUrl: currentUrl,
  prepareHeaders: (headers) => {
      if (accessToken) {
        headers.set("Authorization", `${accessToken}`)
        headers.set("Accept", "application/json")
        // headers.set('Content-type', 'x-www-form-urlencoded')
      }
      return headers
    },
  }),
   
  tagTypes: ["Reviews"],
  endpoints: (builder) => ({
   
    getReviews: builder.query({
      query: () => `/master/reviews`,
      
      providesTags: ["Reviews"],
    }),
    
    updateReviews: builder.mutation({
      query: ({ id, body }) => ({
        url: `/master/reviews/${id}`,
        method: "PATCH", 
        body: body,
      }),
      invalidatesTags: ["Reviews"], 
    }),
  }),
});

export const { useGetReviewsQuery, useUpdateReviewsMutation } = reviewsApi;
