export const convertCategoriesResponseToSnapshots = (categories = []) => {
  if (!categories.length) return []

  return categories.map(
    ({ "sub-category": { id, name, service = [], direction_id } }) => ({
      id: parseInt(id),
      name,
      services: service.map((item) => parseInt(item.id)),
      categoryId: direction_id,
    })
  )
}

export const convertServiceResponseToSnapshots = (services = []) => {
  if (!services.length) return []

  return services.map(({ id, name, price, duration, sub_category_id }) => ({
    id: parseInt(id),
    name,
    price: String(price),
    duration,
    categoryId: sub_category_id,
  }))
}