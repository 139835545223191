import React, { useCallback, useEffect, useState } from "react"
import { Close } from "@mui/icons-material"
import { Modal } from "shared/ui/modal/Modal"

import { useForm } from "react-hook-form"

import dayjs from "dayjs"
import toast from "react-hot-toast"
import { ReviewsTable } from "./ReviewsTable"
import { ReviewsTableRows } from "./ReviewsTableRows"
import {
  useGetReviewsQuery,
  useUpdateReviewsMutation,
} from "../../pages/reviews/ReviewsSlice"

const accessToken = localStorage.getItem("accessToken")

const user = JSON.parse(localStorage.getItem("user"))
const salonId = user?.salon_id
const startTime = user?.salon?.startWork
const endTime = user?.salon?.endWork

const formatDate = (date) => {
  const newDate = new Date(date)
  const year = newDate.getFullYear()
  const month = (newDate.getMonth() + 1).toString().padStart(2, "0") // +1, так как месяцы начинаются с 0
  const day = newDate.getDate().toString().padStart(2, "0")

  return `${year}-${month}-${day}`
}

export const ReviewsForm = ({
  reviewId,
  // setReviews,
  onClose,
  active,
  setActive,
  editingReview,
  setEditingReview,
  type,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
    setError,
    clearErrors,
    control,
    reset,
  } = useForm()
  const [updateReviews] = useUpdateReviewsMutation()
  const { data: reviewsData } = useGetReviewsQuery()
  const [reviews, setReviews] = useState([])

  const formTitle =
    type === "reply" ? "Ответить на отзыв" : "Редактировать отзыв"

  const review = reviewsData?.data?.reviews?.find((r) => r.id === reviewId)

  useEffect(() => {
    if (type === "edit" && review.answer) {
      setValue("message", review.answer)
    }
  }, [type, review, setValue])

  const handleSubmitResponse = async (data) => {
    if (reviewId) {
      try {
        // Отправка запроса на обновление
        const updatedReview = await updateReviews({
          id: reviewId,
          body: { answer: data.message },
        }).unwrap()

        // Убедитесь, что reviews - это массив перед использованием map
        const updatedReviews = Array.isArray(setReviews)
          ? setReviews.map((r) =>
              r.id === reviewId
                ? { ...r, answer: data.message, isAdmin: true }
                : r
            )
          : []

        setReviews(updatedReviews)

        // Отображение успешного сообщения
        toast.success(
          type === "reply" ? "Ответ отправлен!" : "Ответ отредактирован!"
        )

        // Закрытие модала
        onClose()
      } catch (error) {
        console.error("Ошибка при отправке ответа:", error)
        if (error?.response) {
          console.error("Ответ сервера:", error.response)
          toast.error(
            `Ошибка от сервера: ${error.response?.data?.message || "Неизвестная ошибка"}`
          )
        } else {
          toast.error("Ошибка при отправке ответа!")
        }
      }
    }
  }

  return (
    <Modal
      active={active}
      setActive={setActive}
      className='sm:max-w-[800px] max-w-[358px] w-full h-auto sm:h-auto flex gap-6 sm:gap-0 sm:justify-between flex-col mt-[-20px] sm:mt-[0]  '
    >
      <div>
        <div className='flex justify-between items-center'>
          <div className=' flex flex-row justify-between  pb-2  sticky top-0 z-50 bg-white w-full sm:w-full mx-auto sm:mx-[0]'>
            <h2 className='title'>{formTitle}</h2>
            <button onClick={onClose} className='h-fit'>
              <Close
                sx={{
                  color: "gray.main",
                  "&:hover": { color: "gray.dark" },
                }}
              />
            </button>
          </div>
        </div>
      </div>
      <div className='flex flex-col [&>label]:flex [&>label]:flex-col'>
        <ReviewsTableRows review={review} isModal={true} />

        <label className='label-base relative mt-10'>
          Сообщение
          <textarea
            name='message'
            className={"inputOutlined resize-none sm:h-64 h-48 "}
            maxLength={1500}
            placeholder='Введите ваше сообщение'
            {...register("message", { required: "Это поле обязательно" })}
          />
          {/* <ErrorMsg title={errors.message} /> */}
        </label>

        <div className='flex items-center [&>button]:w-full mt-10'>
          <button onClick={onClose} className='btnOutlined mr-4'>
            Отмена
          </button>
          <button
            onClick={handleSubmit(handleSubmitResponse)}
            type='submit'
            className='btnFilled'
          >
         {type === "reply" ? "Ответить" : "Отредактировать"}
          </button>
        </div>
      </div>
    </Modal>
  )
}