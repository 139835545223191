import React from "react"
import { useFormik } from "formik"
import * as yup from "yup"

import { PhoneInput } from "shared/ui/input/PhoneInput"
import { GoBackBtn } from "widgets/auth/GoBackBtn"
import { schemes } from "widgets/auth/validations"
import { ErrorMsg } from "shared/ui/input/ErrorMsg"
import { useEffect, useState } from "react"
import Modal from "../../../../entities/auth/TermsOfUseModal"
import axios from "axios"
import { currentUrl } from "../../../../url"
import toast from "react-hot-toast"

export const PersonalInfo = ({ submit, backSlide, errorMsg }) => {
  const [checkboxState, setCheckboxState] = useState({
    checkbox1: false,
    checkbox2: false,
  })
  const [open, setOpen] = useState(false)
  const [title, setTitle] = useState("")
  const [emailError, setEmailError] = useState(false)
  const [email, setEmail] = useState("")

  const scheme = yup.object().shape({
    name: schemes.name,
    // surname: schemes.name,
    phone: schemes.phone,
    // email: schemes.email,
  })
  const { values, handleChange, handleSubmit, errors, touched, isSubmitting } =
    useFormik({
      initialValues: {
        name: "",
        // surname: "",
        phone: "",
        // email: "",
      },
      validationSchema: scheme,
      validateOnMount: true,
      onSubmit: async (data, { setSubmitting }) => {
        try {
          await submit({ ...data })
          // Например, установить таймер или перейти к следующему шагу
        } catch (error) {
          // Обработка ошибок при сабмите
          toast.error(error.response.data.message)
        } finally {
          setSubmitting(false)
        }
      },
    })

  const handleConfidClick = () => {
    setTitle("Политика конфиденциальности")
    setOpen(true)
  }

  async function nextStep() {
    setEmailError(false)
    try {
      const res = await axios.get(
        `${currentUrl}/master/unique-mail?email=${values?.email}`
      )
      if (!res.data.unique) {
        setEmailError(true)
      }
    } catch (err) {
      console.error(err)
    }
  }

  const validateEmail = (email) => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/
    return emailRegex.test(email)
  }

  useEffect(() => {
    if (values?.email && validateEmail(values?.email)) {
      nextStep()
    }
  }, [values?.email])

  const handleUserAgreementClick = () => {
    setTitle("Пользовательское соглашение")
    setOpen(true)
  }
  const handleCheckboxChange = (name) => {
    setCheckboxState((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }))
  }

  const [timer, setTimer] = useState(0)

  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => {
        setTimer(timer - 1)
      }, 1000)
    }
  }, [timer])

  return (
    <>
      <div className='sm:max-w-[450px] w-full   relative  sm:mt-[-40px]   sm:w-full min-h-[70vh] max-w-[360px] sm:h-[80vh] h-full overflow-y-auto no-scrollbar bigMobile:overflow-hidden  '>
        <div className='text-center  mb-5'>
          <h1 className='text-[27px] font-medium mb-3'>Персональные данные</h1>
          <p className='hidden sm:flex text-darkGray font-normal'>
            Введите свой номер телефона для дальнейший работы с нами
          </p>
        </div>

        <div className='grid  grid-cols-1 sm:grid-cols-2 mb-4 whitespace-nowrap text-ellipsis  '>
          <label className='label-base flex flex-col mobile:mr-2 relative w-[320px] mb-4 sm:w-[217px] mobile:mx-5 sm:m-auto'>
            <p>
              Имя <span className='text-red'>*</span>
            </p>
            <input
              name='name'
              placeholder='Введите имя'
              value={values.name}
              onKeyPress={(e) => {
                const char = String.fromCharCode(e.keyCode || e.which)
                if (!/^[A-Za-zА-Яа-яЁё]$/.test(char)) {
                  e.preventDefault()
                }
              }}
              onInput={(e) => {
                const value = e.target.value
                e.target.value = value.replace(/[^a-zA-Zа-яА-ЯёЁ]/g, "")
              }}
              onChange={handleChange}
              className='inputOutlined'
            />
            {errors.name && touched.name && (
              <ErrorMsg
                className='overflow-hidden whitespace-nowrap text-ellipsis'
                title={errors.name}
              />
            )}
          </label>

          <label className='label-base flex flex-col sm:ml-2 relative w-[320px] sm:w-[217px] mx-5  sm:m-auto '>
            <p>
              Фамилия <span className='text-red'></span>
            </p>
            <input
              name='surname'
              placeholder='Введите фамилию'
              value={values.surname}
              onKeyPress={(e) => {
                const char = String.fromCharCode(e.keyCode || e.which)
                if (!/^[A-Za-zА-Яа-яЁё]$/.test(char)) {
                  e.preventDefault()
                }
              }}
              onInput={(e) => {
                const value = e.target.value
                e.target.value = value.replace(/[^a-zA-Zа-яА-ЯёЁ]/g, "")
              }}
              onChange={handleChange}
              className='inputOutlined'
            />
            {errors.surname && touched.surname && (
              <ErrorMsg
                className='whitespace-nowrap overflow-hidden text-ellipsis'
                title={errors.surname}
              />
            )}
          </label>
        </div>
        <div className='w-full text-left mb-2'>
          <label className='label-base flex flex-col mb-4 relative w-[320px] sm:w-full mx-5 sm:m-auto sm:mt-8'>
            <p>
              Электронная почта <span className='text-red'></span>
            </p>
            <input
              name='email'
              type='email'
              placeholder='example@gmail.com'
              value={values.email}
              onChange={handleChange}
              className='inputOutlined'
            />
            {errors.email && touched.email && (
              <div className='mb-2'>
                <p className='text-red ml-1'>{errors.email}</p>
              </div>
            )}
            {/* {emailError && <ErrorMsg title='Этот email уже занят' />} */}
          </label>

          <label className='label-base flex flex-col mb-7 relative w-[320px] sm:w-full  mx-5 sm:m-auto sm:mt-8'>
            <p className='sm:mb-1'>
              Номер телефона <span className='text-red'>*</span>
            </p>
            <PhoneInput
              name={"phone"}
              value={values.phone}
              onChange={handleChange}
              error={errors.phone && touched.phone}
            />
            {errors.phone && touched.phone && <ErrorMsg title={errors.phone} />}
          </label>
        </div>

        <form className='flex flex-col sm:w-fit '>
          <div className='flex items-start gap-2 w-[320px] sm:w-full  mx-5 sm:m-auto'>
            <input
              type='checkbox'
              name='confidentiality'
              id='confidentiality'
              checked={checkboxState.checkbox1}
              onChange={() => handleCheckboxChange("checkbox1")}
              className='rounded border-gray-300 checked:bg-[#EE6F42] hover:cursor-pointer hover:text-[#EE6F42] focus:text-[#EE6F42]'
            />
            <label
              htmlFor='confidentiality'
              className='hover:cursor-pointer mt-3'
            >
              {/* Я прочитал(а) и принимаю условия{" "} */}
              <p
                onClick={handleConfidClick}
                className='text-[#EE6F42] hover:underline cursor-pointer'
              >
                Политика конфиденциальности{" "}
                <span className='text-black'>и</span>
                {/* Политика */}
              </p>
              <span
                onClick={handleUserAgreementClick}
                className='text-[#EE6F42] hover:underline cursor-pointer'
              >
                Пользовательское соглашение
              </span>
              .
            </label>
          </div>
          <div className='flex items-start gap-2 w-[320px] sm:w-full  mx-5 sm:m-auto'>
            <input
              type='checkbox'
              name='personal'
              id='personal'
              checked={checkboxState.checkbox2}
              onChange={() => handleCheckboxChange("checkbox2")}
              className='rounded border-gray-300 checked:bg-[#EE6F42] hover:cursor-pointer hover:text-[#EE6F42] focus:text-[#EE6F42]'
            />
            <label
              htmlFor='personal'
              className='hover:cursor-pointer mt-3 sm:mt-3'
            >
              {/* Я выражаю  */}
              Согласие на обработку данных
              {/* моих */}
              {/* персональных  */}
              {/* данных */}
              {/* в */}
              {/* соответствии с условиями согласия. */}
            </label>
          </div>
        </form>
        <div className='w-[320px] mx-auto'>
          <button
            type='button'
            disabled={
              !checkboxState.checkbox1 ||
              !checkboxState.checkbox2 ||
              emailError ||
              isSubmitting
            }
            onClick={handleSubmit}
            className={
              !checkboxState.checkbox1 ||
              !checkboxState.checkbox2 ||
              emailError ||
              isSubmitting
                ? "opacity-[50%] btnFilled w-full  mt-[0]  sm:w-full  sm:m-auto"
                : "btnFilled w-full mt-[0]"
            }
          >
            Далее
          </button>
        </div>
        <GoBackBtn handleClick={backSlide} />
        {errorMsg && (
          <ErrorMsg title={errorMsg} className='-bottom-14 text-center mb-5' />
        )}
      </div>
      <Modal open={open} setOpen={setOpen} title={title} />
    </>
  )
}