import { createApi } from "@reduxjs/toolkit/dist/query/react"
import { fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { currentUrl } from "../../url"
import { getApiConfig } from "../../shared/api"
const formatDate = (date) => {
  const newDate = new Date(date)
  const year = newDate.getFullYear()
  const month = (newDate.getMonth() + 1).toString().padStart(2, "0") // +1, так как месяцы начинаются с 0
  const day = newDate.getDate().toString().padStart(2, "0")

  return `${year}-${month}-${day}`
}

const today = new Date()

const accessToken = localStorage.getItem('accessToken')

export const newBookingApi = createApi({
  reducerPath: 'newBookingApi',
  tagTypes: 'newBooking',
  baseQuery: fetchBaseQuery({
    baseUrl: currentUrl,
    prepareHeaders: (headers) => {
      if(accessToken) {
        headers.set('Authorization', `${accessToken}`)
        headers.set('Accept', 'application/json')
        // headers.set('Content-type', 'x-www-form-urlencoded')
      }
      return headers
    }
  }),
  endpoints: build => ({
    getBookings: build.query({
      query: (params) => `/master/search/bookings?keyword=${params.search || ''}&start_date_from=${params.startDate || ''}&start_date_to=${params.endDate || ''}&page=${params.page}&perPage=${params.perPage}&limit=${params.limit}`,
      providesTags: (result) =>
        result?.data
          ? [
            ...result?.data.map(({id}) => ({type: 'newBooking', id})),
            {type: 'newBooking', id: 'LIST'},
          ]
          : [{type: 'newBooking', id: 'LIST'}],
    }),
    getOneBooking: build.query({
      query: (id) => `/master/bookings/${id}`
    }),
    createBooking: build.mutation({
      query: (body) => ({
        url: `/master/bookings`,
        method: 'POST',
        body
      }),
      invalidatesTags: [{type: 'newBooking', id: 'LIST'}]
    }),
    editBooking: build.mutation({
      query: ({ id, ...body }) => ({
        url: `/master/bookings/${id}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: [{type: 'newBooking', id: 'LIST'}]
    }),
    deleteBooking: build.mutation({
      query: (id) => ({
        url: `/master/bookings/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{type: 'newBooking', id: 'LIST'}]
    }),
    deleteServices: build.mutation({
      query: (id) => ({
        url: `/master/bookings/${id}/services`,
        method: 'DELETE',
      }),
      invalidatesTags: [{type: 'newBooking', id: 'LIST'}]
    }),

  })
})

export const {useGetBookingsQuery, useCreateBookingMutation, useEditBookingMutation, useDeleteBookingMutation, useGetOneBookingQuery, useDeleteServicesMutation} = newBookingApi;
