import React from "react"
import { PaginationBlock } from "shared/ui/pagination"
import { ReviewsTableRows } from "./ReviewsTableRows"

export function ReviewsTable({ reviews, onEdit, setReviews }) {
  const currentPage = 1 // Страница по умолчанию
  const rowsPerPage = 5 // Отображаемые записи на странице
  //   const totalPages = Math.ceil(reviews.length / rowsPerPage)

  // Разделение отзывов на страницы
  // const displayedReviews = reviews?.slice(
  //   (currentPage - 1) * rowsPerPage,
  //   currentPage * rowsPerPage
  // )

  return (
    <>
      {reviews?.map((review) => (
        <ReviewsTableRows
          key={review.id}
          review={review}
          onEdit={onEdit}
          setReviews={setReviews}
        />
      ))}

      {/* {reviews.length > rowsPerPage && (
        // <PaginationBlock
        // //   pagesCount={totalPages}
        //   currentPage={currentPage}
        //   setPage={(page) => console.log(page)} // Логика изменения страницы
        // />
      )} */}
    </>
  )
}
