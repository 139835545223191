import React from "react"
import { BookingForms } from "entities/bookings/ui/forms/BookingForms"
import { addMinutes, format } from "date-fns"
import { useCallback, useEffect, useState } from "react"
import {
  useEditBookingMutation,
  useGetOneBookingQuery,
} from "../../../pages/bookings/newBookingApi"
import toast from "react-hot-toast"

export function EditBooking({
  booking,
  active,
  setActive,
  refetch: calendarRefetch,
}) {
  const {
    data: oneBookingData,
    isLoading: oneBookingLoading,
    refetch,
  } = useGetOneBookingQuery(booking?.id, { skip: !booking?.id })
  const [editBooking, { isLoading: editLoading, isSuccess }] =
    useEditBookingMutation()

  const timeToMinutes = useCallback((time) => {
    const [hours, minutes] = time.split(":")
    return parseInt(hours, 10) * 60 + parseInt(minutes, 10)
  }, [])

  useEffect(() => {
    if (isSuccess) {
      setActive(false)
    } else {
      setActive(true)
    }
  }, [])

  const [salonId, setSalonId] = useState(0)

  useEffect(() => {
    const salon = JSON.parse(localStorage.getItem("user"))
    setSalonId(salon?.salon?.id)
  }, [])

  const handleSubmit = async ({ id, ...data }) => {
    try {
      const servicesTimes = data.services.map((item) => item.duration)
      const startTime = new Date(data.start_date)
      let endTime = startTime
      for (const duration of servicesTimes) {
        endTime = addMinutes(endTime, duration)
      }
      const formattedEndTime = format(endTime, "yyyy-MM-dd HH:mm")
      const formattedNumber = data?.phone?.replace(/\D/g, "").slice(3)
      const result = {
        client: {
          name: data.name,
          surname: data.surname,
          phone: formattedNumber,
        },
        service_id: data?.services[0]?.service_id,
        start: data.start_date,
        end: formattedEndTime,
        salon_id: salonId,
        master_id: data?.services[0]?.master_id,
        comment: "123",
        status_id: Number(data.status_id),
        price: Number(data.services.map((item) => item.price).join("")),
      }
      await editBooking({ id: booking?.id, ...result })
      setActive(false)
      refetch()
      if (calendarRefetch) {
        calendarRefetch()
      }
      toast.success("Запись успешно изменена", { position: "bottom-right" })
      // await dispatch(fetchBookings({search: '', startDate: '', endDate: ''}))
    } catch (err) {
      toast.error("Ошибка при изменении записи")
      console.log(err)
    }
  }

  return (
    <BookingForms
      oneBookingData={oneBookingData}
      oneBookingLoading={oneBookingLoading}
      isLoading={editLoading}
      timeToMinutes={timeToMinutes}
      active={active}
      setActive={setActive}
      bookingData={booking}
      onSubmit={handleSubmit}
    />
  )
}
