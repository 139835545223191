import React from "react"
import { Modal } from "../../../../shared/ui/modal/Modal"
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import { useFieldArray, useForm } from "react-hook-form"
import { useEffect, useState } from "react"
import { OneTemplateRow } from "./OneTemplateRow"
import { useDispatch } from "react-redux"
import { setCurrentTemplates } from "./editFormSlice"
import { useUpdateTestMutation } from "../analyzesApi"
import { toast } from "react-hot-toast"

export const TemplateFormModal = ({ active, setActive, items, id }) => {
  const [updateTest, { isLoading: testLoading }] = useUpdateTestMutation()

  const initialFormState = [
    [...items],
    // ... другие поля
  ]

  const [formFields, setFormFields] = useState(initialFormState)

  useEffect(() => {
    setFormFields([...items])
  }, [items])
  const handleInputChange = (index, event) => {
    const newFormFields = [...formFields]

    // Обновляем только значение result для конкретного поля
    if (event.target.name === "result") {
      newFormFields[index] = {
        ...newFormFields[index],
        result: event.target.value,
      }
    }
    setFormFields(newFormFields)
  }

  const onSubmit = async () => {
    const newFields = formFields.map((item) => ({
      id: item.id,
      result: item.result,
    }))
    try {
      for (const item of newFields) {
        if (item.result !== "0") {
          await updateTest({ id: item.id, ...item }).unwrap()
        }
      }
      toast.success("", { position: "bottom-right" })
      setActive(false)
    } catch (e) {
      toast.error("", { position: "bottom-right" })
    }
  }

  return (
    <Modal
      active={active}
      onClose={() => setActive(false)}
      setActive={setActive}
      template
      className=' max-w-[358px] sm:max-w-[800px] p-6'
    >
      <div className='w-full flex items-center justify-between'>
        <h1 className='font-medium text-[20px]'>Общий анализ крови</h1>
        <CloseOutlinedIcon
          color={"disabled"}
          onClick={() => setActive(false)}
          className={"cursor-pointer"}
        />
      </div>
      <div className='flex items-center justify-center w-full mt-10 px-7 border rounded-t-[8px] py-4 border-[#EDEDED]'>
        <p className='w-full whitespace-nowrap font-medium text-[#A4AFBD] text-[12px] mr-8'>
          Наименование
        </p>
        <p className='w-full whitespace-nowrap font-medium text-[#A4AFBD] text-[12px]'>
          Результат
        </p>
        <p className='w-full whitespace-nowrap font-medium text-[#A4AFBD] text-[12px]'>
          Норма
        </p>
        <p className='w-full whitespace-nowrap font-medium text-[#A4AFBD] text-[12px]'>
          Ед. измерения
        </p>
      </div>
      <div className='sm:h-[70vh] overflow-y-auto'>
        {items.map((item, index) => (
          <div
            key={item.id}
            className={`flex items-center justify-center w-full px-7 border-b border-x py-5 border-[#EDEDED] ${index === items.length - 1 && "rounded-b-[8px]"}`}
          >
            <input
              className='border-none outline-none disabled w-full whitespace-nowrap font-medium text-[14px]'
              defaultValue={item.name}
            />
            <input
              name='result'
              value={formFields[index]?.result}
              onChange={(e) => handleInputChange(index, e)}
              defaultValue={item?.result === "0" ? "" : item?.result}
              className='inputOutlined w-[160px] mr-8'
            />
            <input
              className='border-none outline-none disabled w-full whitespace-nowrap font-medium text-[14px]'
              defaultValue={item.norm}
            />
            <input
              className='border-none outline-none disabled w-full whitespace-nowrap font-medium text-[14px]'
              defaultValue={item.unit}
            />
          </div>
        ))}

        <div className='w-full flex items-center justify-end gap-5 mt-6'>
          <button
            onClick={() => setActive(false)}
            type={"button"}
            className='py-3 px-6 border rounded-[8px] border-[#737C86] text-[#737C86] hover:bg-[#1E314833] transition-colors'
          >
            Отмена
          </button>
          <button
            disabled={testLoading}
            onClick={onSubmit}
            type={"button"}
            className={
              testLoading ? "opacity-50 btnFilled px-4" : "btnFilled px-4"
            }
          >
            Сохранить
          </button>
        </div>
      </div>
    </Modal>
  )
}