import React, { useEffect, useState } from "react"
import { MyCheckbox } from "../button/MyCheckbox"
import { TimeInput } from "./TimeInput"

const DaySchedule = ({ day, onChange }) => {
  // const [values, setValues] = useState({
  //   id: day?.id || null,
  //   start_time: day?.start_time || "",
  //   end_time: day?.end_time || "",
  //   lunch_start_time: day?.lunch_start_time || "",
  //   launch_end_time: day?.launch_end_time || "",
  //   day: day?.day || "",
  // })

  // const handleChange = (name, value) => {
  //   setValues((prevValues) => ({ ...prevValues, [name]: value }))
  //   onChange({ ...values, [name]: value })
  // }
  const [values, setValues] = useState({
    id: day?.id || null,
    start_time: day?.start_time || "",
    end_time: day?.end_time || "",
    lunch_start_time: day?.lunch_start_time || "",
    lunch_end_time: day?.lunch_end_time || "",
    day: day?.day || "",
    show_calendar: day?.show_calendar || false,
  })

  useEffect(() => {
    setValues({
      id: day?.id || null,
      start_time: day?.start_time || "",
      end_time: day?.end_time || "",
      lunch_start_time: day?.lunch_start_time || "",
      launch_end_time: day?.launch_end_time || "",
      day: day?.day || "",
      show_calendar: day?.show_calendar || false,
    })
  }, [day])

  const handleChange = (name, value) => {
    setValues((prevValues) => {
      const updatedValues = { ...prevValues, [name]: value }
      onChange(updatedValues) // Pass the updatedValues directly
      return updatedValues
    })
  }
  return (
    <div className='flex w-full  flex-col justify-center items-center   '>
      <span className=' flex w-full items-center'>
        <MyCheckbox name={"show_calendar"} value={day.show_calendar} />

        <p className='text-[16px] font-medium leading-[18px] w-full text-left'>
          {day.day}
        </p>
      </span>
      <div className='flex flex-col gap-3'>
        <div className='grid  grid-cols-2 gap-3'>
          <div className='flex flex-col gap-3'>
            <p>От</p>
            <TimeInput
              name='start_time'
              value={values.start_time}
              onChange={(e) => handleChange("start_time", e.target.value)}
              required
              className='w-full'
            />
          </div>
          <div className='flex flex-col gap-3'>
            <p>До</p>
            <TimeInput
              name='end_time'
              value={values.end_time}
              onChange={(e) => handleChange("end_time", e.target.value)}
              required
              className='w-full'
            />
          </div>
        </div>
        <p className='text-[14px] font-normal leading-4 text-[#1E3148]'>
          Перерыв
        </p>
        <div className='grid  grid-cols-2 gap-3'>
          <div className='flex flex-col gap-3'>
            <p>От</p>
            <TimeInput
              name='lunch_start_time'
              value={values.lunch_start_time}
              onChange={(e) => handleChange("lunch_start_time", e.target.value)}
              required
              className='w-full'
            />
          </div>
          <div className='flex flex-col gap-3'>
            <p>До</p>
            <TimeInput
              name='launch_end_time'
              value={values.launch_end_time}
              onChange={(e) => handleChange("launch_end_time", e.target.value)}
              required
              className='w-full'
            />
          </div>
        </div>
      </div>
      <div />
      <div />
    </div>
  )
}

export default DaySchedule