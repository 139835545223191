import React from "react"
import { Radio } from "@mui/material"

export function RadioBtn({ name, value, checked, onChange, defaultChecked }) {
  return (
    <Radio
      color='secondary'
      size='small'
      sx={{ color: "#A4AFBD", padding: 0, marginRight: 1 }}
      name={name}
      value={value}
      checked={checked}
      defaultChecked={defaultChecked}
      onChange={onChange}
    />
  )
}