import { createApi } from "@reduxjs/toolkit/dist/query/react"
import { baseQueryWithReauth, getApiConfig } from "shared/api"
import { currentUrl } from "../../url"

const { prepareHeaders } = getApiConfig()
const BASE_URL = currentUrl
// const BASE_URL = "//127.0.0.1:8000/api/v1/"

// login config
const LOGIN_PATH = "master/auth/login"
export function formatPhone(phone = "") {
  // take only numbers
  return phone.match(/[0-9]/g).join("").slice(-9)
}

// register config
const SEND_CODE_PATH = "sms/send-code"
const CONFIRM_CODE_PATH = "sms/confirm-code"
const REGISTER_PATH = "master/auth/register"
const UPDATE_PASSWORD_PATH = 'master/auth/forgot-password'

export const authorizationApi = createApi({
  reducerPath: "authorizationApi",
  baseQuery: baseQueryWithReauth({baseUrl: BASE_URL, prepareHeaders, retriesCount: 1}),
  endpoints: (builder) => ({
    // login
    login: builder.mutation({
      query: ({ phone, password }) => ({
        url: `/${LOGIN_PATH}`,
        method: "POST",
        body: { phone: formatPhone(phone), password },
      }),
      transformResponse: ({ data: { accessToken, user } }) => {
        return { accessToken, user }
      },
    }),

    // SMS code api
    sendCode: builder.mutation({
      query: ({ phone, action }) => ({
        // action: 'register', 'login', 'forgot_password', 'update_password', 'update_phone'
        url: `/${SEND_CODE_PATH}`,
        method: "POST",
        body: { phone: formatPhone(phone), action },
      }),
    }),
    confirmCode: builder.mutation({
      query: ({ phone, code }) => ({
        url: `/${CONFIRM_CODE_PATH}`,
        method: "POST",
        body: { phone: formatPhone(phone), code },
      }),
    }),
    // resentCode:

    // register
    register: builder.mutation({
      query: ({ name, surname, email, password, salon_type_id, direction_id, phone, code }) => ({
        url: `/${REGISTER_PATH}`,
        method: "POST",
        body: { name, surname, email, password, salon_type_id, direction_id, phone: formatPhone(phone), code },
        
      }),
    }),

    // update password
    updatePassword: builder.mutation({
      query: ({ phone, newPassword, code }) => ({
        url: `/${UPDATE_PASSWORD_PATH}`,
        method: "POST",
        body: { phone: formatPhone(phone), newPassword, code },
      }),
    }),
  }),
})



export const {
  useLoginMutation,
  useSendCodeMutation,
  useConfirmCodeMutation,
  useRegisterMutation,
  useUpdatePasswordMutation
} = authorizationApi
