import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { getApiConfig } from "shared/api"
import toast from "react-hot-toast"
import { currentUrl } from "../../url"

const { accessToken } = getApiConfig()

type Customer = {
  id: number
  name: string
  surname: string
  phone: string
  email: string | null
  salon_id: 1
}

type CustomersState = {
  customers: Customer[]
  loading: boolean
  error: boolean
  success: boolean
}

export const fetchCustomers = createAsyncThunk<
  Customer[],
  string,
  { rejectValue: boolean }
>("customers/fetchCustomers", async function (search, { rejectWithValue }) {
  try {
     const token = accessToken || localStorage.getItem("accessToken"); // Используем либо глобальный, либо локальный токен
    if (!token) {
      return rejectWithValue(false); // Если токена нет, возвращаем ошибку
    }
      const res = await fetch(
        `${currentUrl}/master/search/clients?keyword=${search}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      if (!res.ok) {
      return rejectWithValue(false); // Если запрос завершился с ошибкой
    }

      const { data } = await res.json()

       if (!Array.isArray(data)) {
      return rejectWithValue(false); // Проверяем, что data является массивом
    }


      const processedData: Customer[] = data?.map((item: any) => ({
        id: item.id,
        name: item.name,
        surname: item.surname,
        phone: item.phone,
        email: item?.email,
        salon_id: item.salon_id,
      }))
      return processedData
    
  } catch (err) {
    toast.error("Ошибка при загрузке данных, попробуйте позже")
      return rejectWithValue(false);
  }
})

const initialState: CustomersState = {
  customers: [],
  loading: false,
  error: false,
  success: false,
}

const customersSlice = createSlice({
  name: "customersSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomers.pending, (state) => {
        state.loading = true
        state.success = false
        state.error = false
      })
      .addCase(fetchCustomers.fulfilled, (state, action) => {
        state.loading = false
        state.error = false
        state.success = true
        state.customers = action.payload
      })
      .addCase(fetchCustomers.rejected, (state) => {
        state.loading = false
        state.success = false
        state.error = true
      })
    // .addCase(searchCustomers.pending, (state) => {
    //   state.loading = true
    //   state.error = false
    // })
    // .addCase(searchCustomers.fulfilled, (state, action) => {
    //   state.loading = false
    //   state.searchCustomers = action.payload
    // })
    // .addCase(searchCustomers.rejected, (state) => {
    //   state.loading = false
    //   state.error = true
    // })
  },
})

export default customersSlice.reducer