// import { useEffect, useState } from "react"

// import ReactInputMask from "react-input-mask"
// import { useFormik } from "formik"

// import { ErrorMsg } from "shared/ui/input/ErrorMsg"
// import { GoBackBtn } from "./GoBackBtn"
// import { schemes } from "./validations"

// export const CodeConfirmation = ({
//   backSlide,
//   submit,
//   errorMsg,
//   resent,
//   hidden1,
// }) => {
//   const [codeTimer, setCodeTimer] = useState(0)

//   function resentCode() {
//     resent()
//     setCodeTimer(30)
//   }

//   useEffect(() => {
//     if (codeTimer > 0) {
//       setTimeout(() => {
//         setCodeTimer(codeTimer - 1)
//       }, 1000)
//     }
//   }, [codeTimer])

//   const [clickTimer, setClickTimer] = useState(0)
//   useEffect(() => {
//     if (clickTimer > 0) {
//       setTimeout(() => {
//         setClickTimer(clickTimer - 1)
//       }, 1000)
//     }
//   }, [clickTimer])

//   const { values, handleChange, errors, touched, handleSubmit, resetForm } =
//     useFormik({
//       initialValues: {
//         code: "",
//       },
//       validationSchema: schemes.getSchemeOf(["code"]),
//       onSubmit: ({ code }) => {
//         submit(code)
//         setClickTimer(5)
//         resetForm()
//       },
//     })

//   return (
//     <div className={hidden1 ? `hidden` : ""}>
//       <div className='max-w-[344px] w-full relative mobile:mt-[10] sm:mt-auto  '>
//         <div className='text-center max-w-[276px] mx-auto  sm:mb-7'>
//           <h1 className='text-[27px] font-medium sm:mb-6'>
//             Вам отправлен код подтверждения
//           </h1>
//         </div>
//         <div className='flex flex-col  mobile:gap-2 sm:gap-0'>
//           <div className='w-full text-left sm:mb-9'>
//             <label className='label-base flex flex-col relative' htmlFor='code'>
//               Код подтверждения
//               <ReactInputMask
//                 name={"code"}
//                 mask={"9999"}
//                 maskPlaceholder=''
//                 className='inputOutlined mt-2 tracking-[5px] w-[320px] sm:w-[275px]'
//                 type='text'
//                 value={values.code}
//                 onChange={handleChange}
//                 placeholder='----'
//                 onKeyDown={(e) =>
//                   e.code === "Enter" &&
//                   values.code.length === 5 &&
//                   handleSubmit()
//                 }
//               />
//               {errors.code && touched.code && <ErrorMsg title={errors.code} />}
//             </label>
//           </div>
//           <div>
//             <div className='h-[20px] mb-3  '>
//               {codeTimer === 0 ? (
//                 <button
//                   onClick={resentCode}
//                   className='text-sm block w-fit mx-auto h-fit'
//                 >
//                   <span
//                     onClick={(e) => e.stopPropagation()}
//                     className='cursor-default'
//                   >
//                     Не пришёл код?{" "}
//                   </span>
//                   <span className='text-orange hover:underline font-medium'>
//                     Отправить повторно
//                   </span>
//                 </button>
//               ) : (
//                 <p className='text-center text-orange mb-3 cursor-default'>
//                   {codeTimer}
//                 </p>
//               )}
//             </div>

//             <button
//               type='button'
//               onClick={handleSubmit}
//               disabled={clickTimer > 0}
//               className='btnFilled w-full  '
//             >
//               Далее
//             </button>
//           </div>
//         </div>
//         <div className='text-center '>
//           <GoBackBtn handleClick={backSlide} />
//         </div>
//         {errorMsg && (
//           <ErrorMsg className='-bottom-[30px] text-center' title={errorMsg} />
//         )}
//       </div>
//     </div>
//   )
// }
import React, { useEffect, useState } from "react"

import ReactInputMask from "react-input-mask"
import { useFormik } from "formik"

import { ErrorMsg } from "shared/ui/input/ErrorMsg"
import { GoBackBtn } from "./GoBackBtn"
import { schemes } from "./validations"

export const CodeConfirmation = ({
  backSlide,
  submit,
  errorMsg,
  resent,
  hidden1,
}) => {
  const [codeTimer, setCodeTimer] = useState(0)

  function resentCode() {
    resent()
    setCodeTimer(30)
  }

  useEffect(() => {
    if (codeTimer > 0) {
      setTimeout(() => {
        setCodeTimer(codeTimer - 1)
      }, 1000)
    }
  }, [codeTimer])

  const [clickTimer, setClickTimer] = useState(0)
  useEffect(() => {
    if (clickTimer > 0) {
      setTimeout(() => {
        setClickTimer(clickTimer - 1)
      }, 1000)
    }
  }, [clickTimer])

  const { values, handleChange, errors, touched, handleSubmit,resetForm } = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema: schemes.getSchemeOf(["code"]),
    onSubmit: ({ code }) => {
      submit(code)
      setClickTimer(5)
      resetForm()
    },
  })

  

  return (
    <div className={hidden1 ? `hidden` : ""}>
      <div className='max-w-[344px] w-full relative mt-24   bigMobile:mt-[10px] sm:mt-auto  '>
        <div className='text-center max-w-[276px] mx-auto  sm:mb-7'>
          <h1 className='text-[27px] font-medium sm:mb-6'>
            Вам отправлен код подтверждения
          </h1>
        </div>
        <div className='flex flex-col mt-10 mb-24  bigMobile:gap-3  sm:gap-0'>
          <div className='w-full text-left sm:mb-9 mb-2 bigMobile:mb-[0]'>
            <label className='label-base flex flex-col  relative' htmlFor='code'>
              Код подтверждения
              <ReactInputMask
                name={"code"}
                mask={"9999"}
                maskPlaceholder=''
                className='inputOutlined mt-2  mb-0 sm:mb-[0]  tracking-[5px] w-[320px] sm:w-[275px]'
                type='text'
                value={values.code}
                onChange={handleChange}
                placeholder='----'
                onKeyDown={(e) =>
                  e.code === "Enter" &&
                  values.code.length === 5 &&
                  handleSubmit()
                }
              />
              {errors.code && touched.code && <ErrorMsg title={errors.code} />}
            </label>
          </div>
          <div>
            <div className='h-[20px] mb-3  mt-4 sm:mt-[0]'>
              {codeTimer === 0 ? (
                <button
                  onClick={resentCode}
                  className='text-sm block w-fit mx-auto h-fit'
                >
                  <span
                    onClick={(e) => e.stopPropagation()}
                    className='cursor-default'
                  >
                    Не пришёл код?{" "}
                  </span>
                  <span className='text-orange hover:underline font-medium'>
                    Отправить повторно
                  </span>
                </button>
              ) : (
                <p className='text-center text-orange mb-3 cursor-default'>
                  {codeTimer}
                </p>
              )}
            </div>

            <button
              type='button'
              onClick={handleSubmit}
              disabled={clickTimer > 0}
              className='btnFilled w-full  '
            >
              Далее
            </button>
          </div>

        <div className='text-center '>
          <GoBackBtn handleClick={backSlide} />
        </div>
        </div>
        {errorMsg && (
          <ErrorMsg className='-bottom-[30px] text-center' title={errorMsg} />
        )}
      </div>
    </div>
  )
}