import React from "react"
import { NavLink, useLocation } from "react-router-dom"
import { getPaths } from "app/homepage/routes"

export function MobileNav() {
  const paths = getPaths(null).filter(({ path }) =>
    [
      "/bookings-calendar",
      "/bookings",
      "/services",
      "/worktime",
      "/profile",
    ].includes(path)
  )
  const { pathname } = useLocation()

  //этот компонент я скрыл

  return (
    <>
      <div className=' hidden h-[60px] w-full  px-[6px] fixed bottom-0 sm:hidden  grid-cols-5 items-center text-center bg-white border-t-[1px] border-lightGray z-20'>
        {paths.map(({ path, value, Icon }, index) => (
          <NavLink
            key={index}
            to={path}
            className={`${
              window.location.pathname === path ? "text-orange" : "text-blue"
            }
              text-sm text-ellipsis flex flex-col px-[3px] whitespace-nowrap
            `}
          >
            <div>
              {
                <Icon
                  sx={{
                    width: "20px",
                    color: `${pathname === path && "secondary.main"}`,
                  }}
                />
              }
            </div>
            <span className='text-[10px] text-ellipsis overflow-hidden'>
              {value}
            </span>
          </NavLink>
        ))}
      </div>
    </>
  )
}
