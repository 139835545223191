import React, { useEffect, useState } from "react"
import { CalendarTodayOutlined } from "@mui/icons-material"
import { TableSpinner } from "../../shared/ui/dataTable/spinner"
import { ReviewsTable } from "../../entities/reviews/ReviewsTable"
import { TableFallback } from "widgets/fallbacks/TableFallback"
import DatePicker from "react-datepicker"
import { useGetReviewsQuery } from "./ReviewsSlice"
import { format } from "date-fns"

export default function Reviews() {
  const [isLoading, setIsLoading] = useState(false)
  const [editingReview, setEditingReview] = useState(null)
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)
  const [selectedDate, setSelectedDate] = useState(null)
  const { data: reviewsData } = useGetReviewsQuery()

  const closeEditForm = () => {
    setEditingReview(null)
  }

  const handleEdit = (review) => {
    setEditingReview(review)
  }

  const toggleCalendar = () => {
    setIsCalendarOpen(!isCalendarOpen)
  }

  const handleDateChange = (date) => {
    setSelectedDate(date)
    setIsCalendarOpen(false) // Закрыть календарь после выбора даты
  }

  const filteredReviews = selectedDate
    ? reviewsData?.data?.reviews?.filter((review) => {
        const reviewDate = format(new Date(review.created_at), "yyyy-MM-dd")
        const selectedFormattedDate = format(selectedDate, "yyyy-MM-dd")
        return reviewDate === selectedFormattedDate
      })
    : reviewsData?.data?.reviews

  return (
    <div className='sm:rounded-lg sm:bg-white p-4 px-6 sm:h-full sm:overflow-y-auto'>
      <div className='flex justify-between items-center mb-4'>
        <div className='text-xl items-center  flex flex-row gap-4'>
          <h1 className='font-bold text-[40px] '>
            {reviewsData?.data?.average_rating}
          </h1>
          <div className='flex flex-col'>
            <span className='text-yellow-500 font-bold text-lg mb-1'>
              {[...Array(5)].map((_, index) => (
                <span key={index}>
                  {index < reviewsData?.data?.average_rating ? "★" : "☆ "}
                </span>
              ))}
            </span>
            <span className='text-darkGray font-normal'>
              {reviewsData?.data?.review_count}{" "}
              {reviewsData?.data?.review_count === 1 ? "отзыв" : "отзыва"}
            </span>
          </div>
        </div>
        {/* <CalendarTodayOutlined style={{ color: "darkGray" }} /> */}
        <div className='relative'>
          <CalendarTodayOutlined
            style={{ color: "darkGray", cursor: "pointer" }}
            onClick={toggleCalendar}
          />
          {isCalendarOpen && (
            <div className='absolute right-0 top-8 z-10 bg-white shadow-md p-2 rounded'>
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                inline
              />
            </div>
          )}
        </div>
      </div>

      {isLoading ? (
        <div className='w-full min-h-[300px] flex justify-center items-center'>
          <TableSpinner />
        </div>
      ) : filteredReviews?.length ? (
        <ReviewsTable reviews={filteredReviews} onEdit={handleEdit} />
      ) : (
        <TableFallback text={"Список записей пуст"} />
      )}
    </div>
  )
}
