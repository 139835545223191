import React from "react"
export function InputWithIcon({
  type,
  name,
  value,
  onChange,
  placeholder,
  className,
  Icon,
  ref,
}) {
  return (
    <div className='relative w-full h-full'>
      <input
        ref={ref}
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={className}
      />
      <div className='absolute right-[10px] top-[50%] translate-y-[-50%]'>
        {Icon}
      </div>
    </div>
  )
}
