import React from "react"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { observer } from "mobx-react-lite"
// import { useDispatch } from "react-redux"

import { useFormik } from "formik"

import { PasswordInput } from "shared/ui/input/PasswordInput"
import { PhoneInput } from "shared/ui/input/PhoneInput"
import { ErrorMsg } from "shared/ui/input/ErrorMsg"
import { schemes } from "widgets/auth/validations"
import { ContentBar } from "./ContentBar"
import { useStore } from "hooks"
import { Footer } from "shared/ui/footer"
import { TableSpinner } from "shared/ui/dataTable/spinner"

export const Login = observer(() => {
  const navigate = useNavigate()
  const [mounted, setMounted] = useState(false)
  const { auth } = useStore()
  const [loading, setLoading] = useState(false)

  const { values, handleChange, handleSubmit, errors, touched } = useFormik({
    initialValues: {
      phone: "",
      password: "",
    },
    validationSchema: schemes.getSchemeOf(["phone", "password"]),
    onSubmit: async ({ phone, password }) => {
      setLoading(true)
      try {
        const res = await auth.loadData({
          phone: phone?.match(/[0-9]/g).join("").slice(-9),
          password,
        })
        // localStorage.setItem("role_name", res.data.data.user.roles[0]?.name)
        localStorage.setItem("user", JSON.stringify(res?.data?.data?.user))
        const user = res?.data?.data?.user
        const userRoleId = user?.roles?.[0]?.id
        // if (res?.data?.data?.user?.step === "0") {
        //   navigate("/settings")
        // } else if (res?.data?.data?.user?.step === "1") {
        //   navigate("/categories")
        // } else if (res?.data?.data?.user?.step === "2") {
        //   navigate("/services")
        // } else if (res?.data?.data?.user?.step === "3") {
        //   navigate("/masters")
        // } else {
        //   navigate("/bookings-calendar")
        // }
        if (res?.data?.data?.user?.salon !== null && userRoleId !== 6) {
          // Logic for "salon" role
          if (res?.data?.data?.user?.step === "0") {
            navigate("/settings")
          } else if (res?.data?.data?.user?.step === "1") {
            navigate("/categories")
          } else if (res?.data?.data?.user?.step === "2") {
            navigate("/services")
          } else if (res?.data?.data?.user?.step === "3") {
            navigate("/masters")
          } else {
            navigate("/bookings-calendar")
          }
        } else if (res?.data?.data?.user?.salon === null) {
          if (res?.data?.data?.user?.step === "0") {
            navigate("/settings")
          } else {
            navigate("/bookings")
          }
        } else if (userRoleId === 6) {
          navigate("/bookings-calendar")
        }

        window.location.reload()

        setMounted(false)
      } catch (e) {
        console.error(e)
      }
    },
  })

  function toggleToChangePassPage() {
    setMounted(false)
    setTimeout(() => {
      navigate("/changePass")
    }, 500)
  }

  function goToSigninPage() {
    setMounted(false)
    setTimeout(() => {
      navigate("/signIn")
    }, 500)
  }

  // animate mounting
  useEffect(() => {
    setMounted(true)
  }, [])

  // if (loading) {
  //   return (
  //     <div className='flex items-center justify-center h-screen w-screen bg-white'>
  //       <TableSpinner />
  //     </div>
  //   )
  // }

  return (
    <div className='sm:w-full grid grid-cols-12 w-full h-full  '>
      <ContentBar mounted={mounted} />
      <div
        className={`${
          mounted ? "opacity-100" : "opacity-0"
        } col-span-12 h-[100%] sm:h-[100vh] sm:mt-0 sm:col-span-6 md:col-span-7 lg:col-span-8 xl:col-span-9 flex items-center flex-col relative justify-center transition-opacity sm:duration-500 p-2`}
        // } flex-1 flex items-center justify-center transition-opacity duration-500`}
      >
        <div className='sm:max-w-[344px] w-[320px] sm:w-full '>
          <div className='text-center max-w-[276px] mx-auto mb-6'>
            <h1 className=' hidden sm:block text-4xl font-medium mb-6'>Вход</h1>
          </div>
          <div className='flex flex-col '>
            <div className='w-full text-left flex flex-col'>
              <label
                htmlFor='tel'
                className='label-base flex flex-col [&>input]:bg-white mb-8 relative'
              >
                <span className='mb-1'>Номер телефона </span>
                <PhoneInput
                  id={"login_phone_input"}
                  name='phone'
                  value={values.phone}
                  onChange={handleChange}
                  error={errors.phone && touched.phone}
                />
                {errors.phone && touched.phone && (
                  <ErrorMsg title={errors.phone} />
                )}
              </label>
              <label className='relative mb-7'>
                <PasswordInput
                  id={"login_password_input"}
                  name='password'
                  value={values.password}
                  label={"Пароль"}
                  placeholder={"Введите пароль"}
                  handleValue={handleChange}
                  error={touched.password && errors.password}
                  onKeyDown={(e) => e.code === "Enter" && handleSubmit()}
                />
              </label>
            </div>
            <div className='text-end '>
              <button
                onClick={toggleToChangePassPage}
                className='
                  mb-2 text-right text-darkGray
                  hover:underline h-fit w-fit
                '
              >
                Забыли пароль?
              </button>
              <button
                type='button'
                disabled={auth.checkingIsAuth}
                onClick={handleSubmit}
                className='btnFilled  w-[320px] sm:w-full mb-4'
              >
                Войти
              </button>

              <div className='mt-1 text-center relative'>
                <span className='text-darkGray cursor-default'>
                  У вас нет аккаунта?
                  <button
                    onClick={goToSigninPage}
                    className='text-orange hover:text-darkOrange font-medium h-fit ml-1'
                  >
                    {" "}
                    Регистрация
                  </button>
                </span>
                {/*{error && <p className="text-red absolute -bottom-8 text-center w-full text-lg">{error}</p>}*/}
                {auth.checkingIsAuth && (
                  <p className='absolute text-darkGray -bottom-8 text-center w-full text-lg'>
                    Выполняется вход...
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  )
})