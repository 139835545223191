import React from "react"
import { forwardRef } from "react"

export const TextArea = forwardRef(
  ({ name, value, onChange, placeholder, className, minLength }, ref) => {
    return (
      <textarea
        className={`${className}
        resize-none w-full min-h-[110px] p-4 mt-1 rounded-lg text-base focus:border-gray
        border-[1px] border-white border-solid hover:border-gray focus:outline-none text-blue
      `}
        maxLength={minLength}
        placeholder={placeholder}
        name={name}
        ref={ref}
        value={value}
        onChange={onChange}
      />
    )
  }
)